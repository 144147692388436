import { handleErrorWithSentry, replayIntegration, feedbackIntegration } from '@sentry/sveltekit';
import * as Sentry from '@sentry/sveltekit';

Sentry.init({
	dsn: 'https://67edd8d3be6f4222a6124683a430a55c@o1422911.ingest.sentry.io/4506633002352640',
	tracesSampleRate: 1.0,

	// This sets the sample rate to be 10%. You may want this to be 100% while
	// in development and sample at a lower rate in production
	replaysSessionSampleRate: 0.1,

	// If the entire session is not sampled, use the below sample rate to sample
	// sessions when an error occurs.
	replaysOnErrorSampleRate: 1.0,

	// If you don't want to use Session Replay, just remove the line below:
	integrations: [
		replayIntegration(),
		feedbackIntegration({
			// Additional SDK configuration goes in here, for example:
			colorScheme: 'light',
			showBranding: false,
			isEmailRequired: true,
			buttonLabel: 'Support',
			submitButtonLabel: 'Send',
			formTitle: 'Support ',
			messagePlaceholder: "Bugs, feedback, or feature requests? We'd love to hear from you!",
			themeLight: {
				submitBorder: '#1c1917',
				submitOutlineFocus: '#1c1917',
				submitBackground: '#1d4ed8',
				submitBackgroundHover: '#93c5fd',
				inputBackground: '#efefef',
				inputBorder: '#1c1917',
				inputOutlineFocus: '#1d4ed8',
			},
		}),
	],
});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
